<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <h1 class="text-h5 pa-3 font-weight-bold accent">
        <v-icon color="primary" left>mdi-account-group</v-icon> Adicionar
        Cliente
      </h1>
      <v-divider></v-divider>
      <v-card-text class="pa-10">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!-- imagem-->
            <v-col cols="12" md="3">
              <!-- <h1 class="text-h6 font-weight-bold mb-4">Logo</h1> -->
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="logo">
                  <v-img :src="logo" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%;">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="secondary">
                        mdi-image-plus
                      </v-icon>

                      <h3 class="text-subtitle-1 ">
                        Arrastar e soltar
                      </h3>
                      <div class="text-caption">
                        Clique para selecionar
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>

            <!-- Dados -->
            <v-col cols="12" md="9" class="px-3">
              <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>

              <v-row>
                <!-- Nome -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="cliente.razao_social"
                    label="Razão Social"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!-- Nome Fantasia -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="cliente.nome_fantasia"
                    label="Nome de Fantasia"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!-- CNPJ -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="
                      cliente.cnpj.length > 11 ? '##.###.###/####-##' : ``
                    "
                    v-model="cliente.cnpj"
                    label="CNPJ"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- INSCRICAO ESTADUAL -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`#########.##-##`"
                    v-model="cliente.inscricao_estadual"
                    label="Inscricao Estadual"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- CPF -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-mask="`###.###.###-##`"
                    v-model="cliente.cpf"
                    label="CPF"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- RG -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-mask="`#######-#`"
                    v-model="cliente.rg"
                    label="RG"
                    dense
                  ></v-text-field>
                </v-col>

                <!--CI -->
                <v-col cols="12" md="2">
                  <v-text-field label="CI" v-model="cliente.ci" dense>
                  </v-text-field>
                </v-col>

                <!-- Ramo Negocio -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="cliente.ramo_negocio"
                    label="Ramo de Negócio"
                    dense
                  ></v-text-field>
                </v-col>

                <!--E-mail  -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="cliente.email"
                    label="E-mail"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.telefone"
                    v-mask="`(##) ####-####`"
                    label="Telefone"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.celular"
                    v-mask="`(##) #.####-####`"
                    label="Celular"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!-- whatsapp -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.whatsapp"
                    v-mask="`(##) #.####-####`"
                    label="Whatsapp"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Telegram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.telegram"
                    v-mask="`(##) #.####-####`"
                    label="Telegram"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Endereco -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
              <v-row>
                <!-- CEP -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cep"
                    v-mask="`#####-###`"
                    label="CEP"
                    dense
                    append-icon="mdi-magnify"
                    @keyup.enter="fetchCep"
                    @click:append="fetchCep"
                    :loading="loadingCep"
                  ></v-text-field>
                </v-col>

                <!--endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Endereço"
                    v-model="cliente.endereco"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>

                <!--('numero')- -->
                <v-col cols="12" md="3">
                  <v-text-field label="Número" v-model="cliente.numero" dense>
                  </v-text-field>
                </v-col>

                <!--('('bairro')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field label="Bairro" v-model="cliente.bairro" dense>
                  </v-text-field>
                </v-col>

                <!--('('complemento')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Complemento"
                    v-model="cliente.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="3">
                  <v-text-field label="Cidade" v-model="cliente.cidade" dense>
                  </v-text-field>
                </v-col>

                <!-- UF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.uf"
                    label="UF"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Contatos -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
              <v-row>
                <!--Site -->
                <v-col cols="12" md="12">
                  <v-text-field label="Site" v-model="cliente.site" dense>
                  </v-text-field>
                </v-col>

                <!-- Instagram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Instagram"
                    v-model="cliente.instagram"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Facebook -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Facebook"
                    v-model="cliente.facebook"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Linkedin -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Linkedin"
                    v-model="cliente.linkedin"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Youtube -->
                <v-col cols="12" md="3">
                  <v-text-field label="Youtube" v-model="cliente.youtube" dense>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Descrição dos serviços contratados -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold">
                Descrição dos serviços contratados
              </h1>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="cliente.descricao_servicos"
                    placeholder="Insira aqui a descrição dos serviços..."
                    auto-grow
                    :rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>

            <!--Prazo do contrato -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">
                Prazo do contrato
              </h1>

              <v-row>
                <v-col cols="12" md="6">
                  <DataField
                    label="Inicio em"
                    :data_sync.sync="cliente.inicio_contrato"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <DataField
                    label="Término em"
                    :data_sync.sync="cliente.termino_contrato"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn :to="{ path: '/gestor/clientes' }" exact>
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="createCliente"
          :disabled="!validForm"
          color="secondary"
          class="white--text"
        >
          Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { postCliente } from "@/api/gestor/clientes.js";
import { inputRequired } from "@/helpers/utils.js";
import { buscaCep } from "@/api/geral/buscaCep.js";
import { format } from "date-fns";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "NovoCliente",

  data() {
    return {
      loading: false,
      cliente: {
        logo: "",
        nome_fantasia: "",
        razao_social: "",
        cpf: "",
        rg: "",
        cnpj: "",
        status: 1,
        inscricao_estadual: "",
        ramo_negocio: "",
        site: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        email: "",
        data_cadastro: "",
        descricao_servicos: "",
        inicio_contrato: "",
        termino_contrato: "",
      },
      validForm: true,
      formRules: [inputRequired],
      hoverFile: false,
      logo: null,
      cep: "",
      logoUpload: null,
      loadingCep: false,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
    DataField: () => import("../../../components/fields/DataField.vue"),
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.cliente.cep = "";
        this.cliente.cidade = "";
        this.cliente.bairro = "";
        this.cliente.uf = "";
        this.cliente.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.cliente.cep = response.cep;
            this.cliente.cidade = response.city;
            this.cliente.bairro = response.neighborhood;
            this.cliente.uf = response.state;
            this.cliente.endereco = response.street ? response.street : '';
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    fileAdded(file) {
      this.logoUpload = file;

      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    createCliente() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const cliente = new FormData();
        if (this.logoUpload) {
          cliente.append("logo", this.logoUpload, this.logoUpload.name);
          cliente.append("logo64", this.logo);
        }
        cliente.append("razao_social", this.cliente.razao_social);
        cliente.append("nome_fantasia", this.cliente.nome_fantasia);
        cliente.append("status", this.cliente.status);
        cliente.append("cnpj", this.cliente.cnpj);
        cliente.append("cpf", this.cliente.cpf);
        cliente.append("inscricao_estadual", this.cliente.inscricao_estadual);
        cliente.append("rg", this.cliente.rg);
        cliente.append("ramo_negocio", this.cliente.ramo_negocio);
        cliente.append("instagram", this.cliente.instagram);
        cliente.append("facebook", this.cliente.facebook);
        cliente.append("linkedin", this.cliente.linkedin);
        cliente.append("youtube", this.cliente.youtube);
        cliente.append("endereco", this.cliente.endereco);
        cliente.append("numero", this.cliente.numero);
        cliente.append("bairro", this.cliente.bairro);
        cliente.append("complemento", this.cliente.complemento);
        cliente.append("cep", this.cliente.cep);
        cliente.append("uf", this.cliente.uf);
        cliente.append("cidade", this.cliente.cidade);
        cliente.append("celular", this.cliente.celular);
        cliente.append("whatsapp", this.cliente.whatsapp);
        cliente.append("telegram", this.cliente.telegram);
        cliente.append("email", this.cliente.email);
        cliente.append("ci", this.cliente.ci);
        cliente.append("data_cadastro", format(new Date(), "yyyy-MM-dd"));
        cliente.append("descricao_servicos", this.cliente.descricao_servicos);
        if (this.cliente.inicio_contrato) {
          cliente.append("inicio_contrato", this.cliente.inicio_contrato);
        }
        if (this.cliente.termino_contrato) {
          cliente.append("termino_contrato", this.cliente.termino_contrato);
        }

        postCliente(cliente)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Cliente adicionado com sucesso!");
              this.$router.push({ path: "/gestor/clientes" });
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}

#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
